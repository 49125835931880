<template>
  <div>
    <!-- Hero -->
    <div class="hero bg-white">
      <div class="content content-full text-center">
                  <img src="https://yosoyvendedor.com/source/6af50bd2b8e8529f0a2dc2fe5ff2d951/602c2c51ba17d6011.png" alt="" width="340px" height="130px">

        <b-row class="justify-content-center">

          <b-col lg="8" xl="6">
            <!-- <i class="fa fa-2x fa-circle-notch text-primary"></i> -->
          <br>
            <p class="font-size-lg font-w500 text-muted mb-5">
              Una empresa líder en la comercialización y transformación
              de productos de acero.
            </p>
          </b-col>
        </b-row>
        <b-button
          size="sm"
          variant="alt-primary"
          class="px-4 py-2 m-2"
          to="/auth/signin2"
          v-click-ripple
        >
          Iniciar Sesion

          <i class="fa fa-fw fa-arrow-right ml-1"></i>
        </b-button>
      </div>
    </div>
    <!-- END Hero -->
  </div>
</template>

<script>
import { BButton, BCol, BRow } from "bootstrap-vue";

export default {
  name: "Landing",
  components: { BButton, BCol, BRow },
};
</script>
